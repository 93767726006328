import React, { Suspense, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Canvas, useFrame } from '@react-three/fiber'
import { Environment, Loader, PerspectiveCamera } from '@react-three/drei'
import { isBrowser } from '@emotion/utils'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { animated, useSpring } from '@react-spring/web'

import RobotAnimated from '../components/RobotAnimated'
import { SwipeDownIcon } from '../components/ui/icons'
import Overlay from '../components/Overlay'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import {
  Container,
  breakpoints,
  space,
  fontSizes,
  TwoColumnGrid,
  colors,
  Button,
} from '../utils/styles'
import {
  useOnScreen,
  useInViewportAnimation,
  useWindowDimensions,
} from '../utils/hooks'
import video from '../images/video.mp4'

const Background = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  background-color: #101010;
  background: radial-gradient(circle at bottom center, #212121 0%, #101010 80%);
`

const Wrapper = styled.div`
  width: 100%;
  background-color: #101010;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content center;
  padding: ${space[10]} 0;

  @media (max-width: ${breakpoints.l}px) {
    padding: 0px;
  }
`
const H2 = styled.h2`
  color: white;
  font-size: ${fontSizes['4xl']};
  margin-bottom: ${space['2.5']};
  word-wrap: break-word;
  font-weight: 600;
  margin: 0 0 0.5rem;
  line-height: 1.4;
  text-transform: uppercase;
`
const Text = styled.p`
  color: ${colors.grey};
  line-height: 1.6em;
  font-size: ${fontSizes.lg};
`

const Video = styled.video`
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: contain;
`

const MediaWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`

const ScrollIconWrapper = styled.div`
  z-index: -1;
  position: fixed;
  width: 100%;
  height: 20%;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DisableRender = () => useFrame(() => null, 1000)

const IndexPage = () => {
  useInViewportAnimation()
  const { width } = useWindowDimensions()
  const [iconVisible, setIconVisible] = useState(false)
  const intl = useIntl()
  const locale = intl.locale !== 'de' ? `/${intl.locale}` : ''

  const overlay = useRef()
  const caption = useRef()
  const scroll = useRef(0)
  const containerRef = useRef()

  const containerIsVisible = useOnScreen(containerRef, '-200px')

  // Fade in SwipeDownIcon
  useEffect(() => {
    setTimeout(() => setIconVisible(true), 1000)
  }, [])

  // Fade out SwipeDownIcon
  useEffect(() => {
    const overlayElem = overlay.current
    const onScroll = () => {
      setTimeout(() => {
        setIconVisible(false)
        overlayElem.removeEventListener('scroll', onScroll)
      }, 1000)
    }
    overlayElem.addEventListener('scroll', onScroll)

    return () => overlayElem.removeEventListener('scroll', onScroll)
  }, [])

  const iconStyle = useSpring({ opacity: iconVisible ? 0.8 : 0 })

  return (
    <Background>
      <Navigation color="white" position="absolute" />

      <Loader dataInterpolation={p => `Loading ${p.toFixed(2)}%`} />

      <ScrollIconWrapper>
        <animated.div style={iconStyle}>
          <SwipeDownIcon
            color="white"
            height={width > breakpoints.l ? 60 : 40}
          />
        </animated.div>
      </ScrollIconWrapper>

      <Canvas
        gl={{ antialias: false }}
        frameloop="demand"
        dpr={Math.max(isBrowser ? window.devicePixelRatio : 0, 2)}
        onCreated={state => state.events.connect(overlay.current)}
        raycaster={{
          computeOffsets: ({ clientX, clientY }) => ({
            offsetX: clientX,
            offsetY: clientY,
          }),
          // This inverts which objects are hit first when raycasting.
          // filter: intersects => intersects.reverse(),
        }}
      >
        {containerIsVisible && <DisableRender />}
        <PerspectiveCamera
          makeDefault
          name="Main_Camera"
          far={10000000000}
          near={100}
          fov={22.895192}
          position={[-472.490112, 250, 385.937195]}
          rotation={[-0.403151, -0.98256, -0.340966]}
        />
        <ambientLight intensity={0.4} />
        <Suspense fallback={null}>
          <RobotAnimated scroll={scroll} />
          <Environment preset="city" />
        </Suspense>
      </Canvas>

      <Overlay ref={overlay} caption={caption} scroll={scroll}>
        <Wrapper>
          <Container
            style={{
              paddingTop: space[20],
              paddingBottom: space[20],
              overflow: 'hidden',
            }}
            ref={containerRef}
          >
            <TwoColumnGrid gap={space[20]}>
              <MediaWrapper data-inviewport="fade-in-left">
                <Video loop muted playsInline autoPlay>
                  <source src={video} type="video/mp4" />
                </Video>
              </MediaWrapper>
              <TextWrapper>
                {intl.locale === 'de' ? (
                  <H2>Im Einsatz</H2>
                ) : (
                  <H2>In Action</H2>
                )}
                {intl.locale === 'de' ? (
                  <Text>
                    Das Herga Konstruktionsbüro Lightweight-System ist in fast
                    allen Bereichen einsetzbar. Je nach Bedarf, kann somit der
                    Greifer auf Ihren Wunsch angepasst werden. In unserem
                    Vorführungsvideo wurde ein frisch gefrästes Teil durch
                    Pressluft von den Metallresten befreit und anschließend mit
                    einem Vakuum-Sauger abtransportiert. Die im Greifer
                    integrierten Ventile können mit dem zugehörigem Programm
                    individuell eingesetzt werden.
                  </Text>
                ) : (
                  <Text>
                    The Herga design office lightweight system can be used in
                    almost all areas. Depending on your needs, the gripper can
                    thus be adapted to your requirements. In our demonstration
                    video, a freshly milled part was freed from metal residues
                    using compressed air and then removed with a vacuum suction
                    device. The valves integrated in the gripper can be used
                    individually with the provided program.
                  </Text>
                )}
              </TextWrapper>

              <div data-inviewport="fade-in-right">
                <a href="/Vielfaeltige-Moeglichkeiten.jpg" target="_blank">
                  <StaticImage
                    src="../images/Vielfaeltige-Moeglichkeiten-Beige.jpg"
                    layout="fullWidth"
                    alt="Vielfältige Möglichekeiten"
                    loading="lazy"
                    objectFit="contain"
                  />
                </a>
              </div>
              <TextWrapper>
                {intl.locale === 'de' ? (
                  <H2>Vielfältige Möglichkeiten</H2>
                ) : (
                  <H2>Various possibilities</H2>
                )}
                {intl.locale === 'de' ? (
                  <Text>
                    Durch die eigenständige Konfiguration des Lightweight -
                    System, haben Sie je nach Aufgabenbereich die richtige
                    Lösung parat. Die Starterbox bietet eine Vielzahl an
                    Möglichkeiten, welche auch durch den Nachkauf einzelner Teil
                    aus dem{' '}
                    <a
                      href="https://konstruktionsbuero-herga.de/leichtbau-saugsysteme"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Herga Leichtbau Saugsystem
                    </a>{' '}
                    noch erweitert werden können.
                  </Text>
                ) : (
                  <Text>
                    Thanks to the independent configuration of the lightweight
                    system, you have the right solution at hand depending on the
                    area of responsibility. The starter box offers a variety of
                    options, which can also be expanded by purchasing individual
                    parts from the{' '}
                    <a
                      href="https://konstruktionsbuero-herga.de/leichtbau-saugsysteme"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Herga lightweight suction system
                    </a>
                    .
                  </Text>
                )}
              </TextWrapper>

              <div data-inviewport="fade-in-left">
                <a href="/Karton.jpg" target="_blank">
                  <StaticImage
                    src="../images/Karton.jpg"
                    layout="fullWidth"
                    alt="Karton"
                    loading="lazy"
                    objectFit="contain"
                    className="invisible fade-in-left"
                  />
                </a>
              </div>
              <TextWrapper>
                {intl.locale === 'de' ? <H2>Lieferumfang</H2> : <H2>Parts</H2>}
                {intl.locale === 'de' ? (
                  <Text>
                    Das Lightweight System kommt mit einem umfangreichen Satz an
                    Zubehör und Kleinteilen, welche einen sofortigen und
                    einfachen Einsatz ermöglichen.
                  </Text>
                ) : (
                  <Text>
                    The Lightweight System comes with an extensive set of
                    accessories and small parts that provide an immediate and
                    enable easy start.
                  </Text>
                )}
                <Button backgroundColor={colors.hergaBlue}>
                  <a href="/downloads/Deckblatt_2019_03.pdf" target="_blank">
                    {intl.formatMessage({ id: 'pdfButton' })}
                  </a>
                </Button>
              </TextWrapper>
            </TwoColumnGrid>
            <TextWrapper style={{ textAlign: 'center', marginTop: space[40] }}>
              <H2>{intl.formatMessage({ id: 'contact' })}</H2>
              {intl.locale === 'de' ? (
                <Text>
                  Wir sind sicher, dass unser Lightweight - System die perfekten
                  Anpassungsmöglichkeiten für Sie bietet.
                  <br />
                  Gerne beraten wir Sie auch individuell zu den Einsatzgebieten
                  und Erweiterungsmöglichkeiten.
                  <br />
                  <br />
                  Schreiben Sie uns einfach eine Email an:
                  <br />
                  <br />
                  <a
                    href="mailto:info@konstruktionsbuero-herga.de"
                    rel="noreferrer"
                  >
                    info@konstruktionsbuero-herga.de
                  </a>
                </Text>
              ) : (
                <Text>
                  We are sure our lightweight system offers you the perfect
                  adjustment options.
                  <br /> We would be happy to advise you individually on the
                  areas of application and expansion options.
                  <br />
                  <br />
                  Just write us an email to:
                  <br />
                  <br />
                  <a
                    href="mailto:info@konstruktionsbuero-herga.de"
                    rel="noreferrer"
                  >
                    info@konstruktionsbuero-herga.de
                  </a>
                </Text>
              )}
            </TextWrapper>
          </Container>
          <Footer color="white" />
        </Wrapper>
      </Overlay>
    </Background>
  )
}

export default IndexPage
