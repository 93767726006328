import React from 'react'
import { Link } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import { useSiteMetadata } from '../../utils/hooks'
import { Container } from '../../utils/styles'
import { Wrapper, Item, Flags } from './styles'
import { USAFlag, GermanyFlag } from './icons'

const Footer = ({ color = 'black' }) => {
  const { title, secondaryNav } = useSiteMetadata()
  const intl = useIntl()

  return (
    <Container>
      <Wrapper color={color}>
        {secondaryNav.map(({ name, link }) => (
          <Item to={link} key={name} color={color}>
            {name}
          </Item>
        ))}
        <span>
          © {new Date().getFullYear()} {title}
        </span>
        <span>
          Konzept: &nbsp;
          <a style={{ color: color }} href="https://wertgebung.de">
            WERTGEBUNG
          </a>
          &nbsp; Entwicklung: &nbsp;
          <a style={{ color: color }} href="https://alexanderhoerl.de">
            Alexander Hörl
          </a>
        </span>
        <Flags>
          <Link to="/">
            <GermanyFlag height={16} />
          </Link>
          <Link to="/en">
            <USAFlag height={16} />
          </Link>
        </Flags>
      </Wrapper>
    </Container>
  )
}

export default Footer
