/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef, useEffect } from 'react'
import { useGLTF, PerspectiveCamera, useAnimations } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export default function Model({ scroll, ...props }) {
  const t = useRef(0)
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/robotAnimated.glb')
  const { actions, mixer } = useAnimations(animations, group)

  useEffect(() => void actions['animation_0'].play(), [actions])
  useFrame(() => {
    mixer.setTime(
      (t.current = THREE.MathUtils.lerp(
        t.current,
        actions['animation_0']._clip.duration * scroll.current,
        0.05
      ))
    )
  })

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      onClick={e => console.log(e.object.name)}
    >
      <group>
        <group name="UR3e" rotation={[-Math.PI / 2, 0, -Math.PI]} scale={400}>
          <group name="Axis-1" rotation={[Math.PI / 2, Math.PI / 4, 0]}>
            <group
              name="Axis-2"
              position={[0, 0.152, 0]}
              rotation={[-Math.PI / 2, Math.PI / 3, 0]}
            >
              <group
                name="Axis-3"
                position={[-0.244, 0.129548, 0]}
                rotation={[0, -0.087266, -Math.PI]}
              >
                <group
                  name="Axis-5"
                  position={[0, 0.11503, 0.213]}
                  rotation={[3.141454, 0.610865, 0.000056]}
                >
                  <group
                    name="_077"
                    position={[-0.0004, 0.116532, 0]}
                    rotation={[Math.PI, -0.000001, Math.PI / 2]}
                    scale={[0.001, 0.001, 0.001]}
                  >
                    <group
                      name="_078"
                      position={[-0.000015, 0, 0.000002]}
                      rotation={[0, 0, 0]}
                      scale={[1, 1, 1]}
                    >
                      <mesh
                        name="_079"
                        geometry={nodes._079.geometry}
                        material={nodes._079.material}
                        position={[-0.000015, 0, 0.000002]}
                        rotation={[0, 0, 0]}
                        scale={[1, 1, 1]}
                      />
                    </group>
                    <group
                      name="_080"
                      position={[-0.000015, 0, 0.000002]}
                      rotation={[0, 0, 0]}
                      scale={[1, 1, 1]}
                    >
                      <mesh
                        name="_081"
                        geometry={nodes._081.geometry}
                        material={nodes._081.material}
                        position={[-0.000015, 0, 0.000002]}
                        rotation={[0, 0, 0]}
                        scale={[1, 1, 1]}
                      />
                      <mesh
                        name="_082"
                        geometry={nodes._082.geometry}
                        material={nodes._082.material}
                        position={[-0.000015, 0, 0.000002]}
                        rotation={[0, 0, 0]}
                        scale={[1, 1, 1]}
                      />
                      <mesh
                        name="_083"
                        geometry={nodes._083.geometry}
                        material={nodes._083.material}
                        position={[-0.000015, 0, 0.000002]}
                        rotation={[0, 0, 0]}
                        scale={[1, 1, 1]}
                      />
                      <mesh
                        name="_084"
                        geometry={nodes._084.geometry}
                        material={nodes._084.material}
                        position={[-0.000015, 0, 0.000002]}
                        rotation={[0, 0, 0]}
                        scale={[1, 1, 1]}
                      />
                      <mesh
                        name="_086"
                        geometry={nodes._086.geometry}
                        material={nodes._086.material}
                        position={[-0.000015, 0, 0.000002]}
                        rotation={[0, 0, 0]}
                        scale={[1, 1, 1]}
                      />
                    </group>
                  </group>
                  <group
                    name="Axis-6"
                    position={[-0.042275, 0.116482, 0]}
                    rotation={[0.00002, -0.000002, -Math.PI / 2]}
                  >
                    <group
                      name="_087"
                      position={[0, 0.127225, -0.00005]}
                      rotation={[Math.PI / 2, -Math.PI / 2, 0]}
                      scale={0.001}
                    >
                      <group
                        name="_090"
                        position={[0.000031, 0.000009, 0]}
                        rotation={[0, 0, 0]}
                      >
                        <mesh
                          name="_091"
                          geometry={nodes._091.geometry}
                          material={nodes._091.material}
                          position={[0.000031, 0.000009, 0]}
                          rotation={[0, 0, 0]}
                        />
                        <mesh
                          name="_092"
                          geometry={nodes._092.geometry}
                          material={nodes._092.material}
                          position={[0.000031, 0.000009, 0]}
                          rotation={[0, 0, 0]}
                        />
                        <mesh
                          name="_093"
                          geometry={nodes._093.geometry}
                          material={nodes._093.material}
                          position={[0.000031, 0.000009, 0]}
                          rotation={[0, 0, 0]}
                        />
                        <mesh
                          name="_094"
                          geometry={nodes._094.geometry}
                          material={nodes._094.material}
                          position={[0.000031, 0.000009, 0]}
                          rotation={[0, 0, 0]}
                        />
                        <mesh
                          name="_095"
                          geometry={nodes._095.geometry}
                          material={nodes._095.material}
                          position={[0.000031, 0.000009, 0]}
                          rotation={[0, 0, 0]}
                        />
                        <mesh
                          name="_096"
                          geometry={nodes._096.geometry}
                          material={nodes._096.material}
                          position={[0.000031, 0.000009, 0]}
                          rotation={[0, 0, 0]}
                        />
                      </group>
                    </group>
                    <group
                      name="Axis-7"
                      position={[0, 0.127275, -0.023981]}
                      rotation={[Math.PI / 2, 0, Math.PI]}
                    >
                      <group
                        name="_097"
                        position={[-0.00005, 0.068169, 0]}
                        rotation={[Math.PI / 2, 0, Math.PI / 2]}
                        scale={0.001}
                      >
                        <group
                          name="Sauger_Zangen_1stp"
                          position={[-67.980118, 39.632519, -58.632408]}
                          rotation={[0, 0.000026, -0.000109]}
                          scale={10}
                        >
                          <group
                            name="004_2050_Rev1"
                            position={[10.070618, -3.920179, 1.171159]}
                            rotation={[0, 0, 0]}
                          >
                            <group
                              name="001_2003"
                              position={[-0.68399, 2.005057, 3.742971]}
                              rotation={[-Math.PI / 2, 0, 0]}
                            >
                              <mesh
                                name="Volumenkörper1(3)"
                                geometry={nodes['Volumenkörper1(3)'].geometry}
                                material={nodes['Volumenkörper1(3)'].material}
                                position={[0, 0, 0]}
                              />
                            </group>
                            <group
                              name="001_2003_2"
                              position={[-6.18399, 2.005057, 3.742971]}
                              rotation={[-Math.PI / 2, 0, 0]}
                            >
                              <mesh
                                name="Volumenkörper1(3)_1"
                                geometry={nodes['Volumenkörper1(3)_1'].geometry}
                                material={nodes['Volumenkörper1(3)_1'].material}
                              />
                            </group>
                            <group
                              name="002_2001"
                              position={[-4.56199, 1.325057, 3.227971]}
                              rotation={[Math.PI, -1.570535, 0]}
                            >
                              <mesh
                                name="Volumenkörper1(5)"
                                geometry={nodes['Volumenkörper1(5)'].geometry}
                                material={nodes['Volumenkörper1(5)'].material}
                                position={[0, 0, 0]}
                              />
                              <mesh
                                name="Volumenkörper1(6)"
                                geometry={nodes['Volumenkörper1(6)'].geometry}
                                material={nodes['Volumenkörper1(6)'].material}
                                position={[0, 0, 0]}
                              />
                              <mesh
                                name="Volumenkörper1(7)"
                                geometry={nodes['Volumenkörper1(7)'].geometry}
                                material={nodes['Volumenkörper1(7)'].material}
                                position={[0, 0, 0]}
                              />
                              <mesh
                                name="Volumenkörper1(8)"
                                geometry={nodes['Volumenkörper1(8)'].geometry}
                                material={nodes['Volumenkörper1(8)'].material}
                                position={[0, 0, 0]}
                              />
                              <mesh
                                name="Volumenkörper1(9)"
                                geometry={nodes['Volumenkörper1(9)'].geometry}
                                material={nodes['Volumenkörper1(9)'].material}
                                position={[0, 0, 0]}
                              />
                              <mesh
                                name="Volumenkörper1(10)"
                                geometry={nodes['Volumenkörper1(10)'].geometry}
                                material={nodes['Volumenkörper1(10)'].material}
                                position={[0, 0, 0]}
                              />
                              <mesh
                                name="Volumenkörper1(11)"
                                geometry={nodes['Volumenkörper1(11)'].geometry}
                                material={nodes['Volumenkörper1(11)'].material}
                                position={[0, 0, 0]}
                              />
                              <mesh
                                name="Volumenkörper1(12)"
                                geometry={nodes['Volumenkörper1(12)'].geometry}
                                material={nodes['Volumenkörper1(12)'].material}
                                position={[0, 0, 0]}
                              />
                              <mesh
                                name="Volumenkörper1(13)"
                                geometry={nodes['Volumenkörper1(13)'].geometry}
                                material={nodes['Volumenkörper1(13)'].material}
                                position={[0, 0, 0]}
                              />
                              <mesh
                                name="Volumenkörper1(14)"
                                geometry={nodes['Volumenkörper1(14)'].geometry}
                                material={nodes['Volumenkörper1(14)'].material}
                                position={[0, 0, 0]}
                              />
                              <mesh
                                name="Volumenkörper1(15)"
                                geometry={nodes['Volumenkörper1(15)'].geometry}
                                material={nodes['Volumenkörper1(15)'].material}
                                position={[0, 0, 0]}
                              />
                              <mesh
                                name="Volumenkörper1(16)"
                                geometry={nodes['Volumenkörper1(16)'].geometry}
                                material={nodes['Volumenkörper1(16)'].material}
                                position={[0, 0, 0]}
                              />
                            </group>
                            <group
                              name="002_2001_2"
                              position={[-4.56199, 1.325057, 2.227971]}
                              rotation={[Math.PI, -1.570535, 0]}
                            >
                              <group name="002_2001_1">
                                <mesh
                                  name="Volumenkörper1(5)_1"
                                  geometry={
                                    nodes['Volumenkörper1(5)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(5)_1'].material
                                  }
                                />
                                <mesh
                                  name="Volumenkörper1(6)_1"
                                  geometry={
                                    nodes['Volumenkörper1(6)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(6)_1'].material
                                  }
                                />
                                <mesh
                                  name="Volumenkörper1(7)_1"
                                  geometry={
                                    nodes['Volumenkörper1(7)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(7)_1'].material
                                  }
                                />
                                <mesh
                                  name="Volumenkörper1(8)_1"
                                  geometry={
                                    nodes['Volumenkörper1(8)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(8)_1'].material
                                  }
                                />
                                <mesh
                                  name="Volumenkörper1(9)_1"
                                  geometry={
                                    nodes['Volumenkörper1(9)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(9)_1'].material
                                  }
                                />
                                <mesh
                                  name="Volumenkörper1(10)_1"
                                  geometry={
                                    nodes['Volumenkörper1(10)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(10)_1'].material
                                  }
                                />
                                <mesh
                                  name="Volumenkörper1(11)_1"
                                  geometry={
                                    nodes['Volumenkörper1(11)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(11)_1'].material
                                  }
                                />
                                <mesh
                                  name="Volumenkörper1(12)_1"
                                  geometry={
                                    nodes['Volumenkörper1(12)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(12)_1'].material
                                  }
                                />
                                <mesh
                                  name="Volumenkörper1(13)_1"
                                  geometry={
                                    nodes['Volumenkörper1(13)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(13)_1'].material
                                  }
                                />
                                <mesh
                                  name="Volumenkörper1(14)_1"
                                  geometry={
                                    nodes['Volumenkörper1(14)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(14)_1'].material
                                  }
                                />
                                <mesh
                                  name="Volumenkörper1(15)_1"
                                  geometry={
                                    nodes['Volumenkörper1(15)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(15)_1'].material
                                  }
                                />
                                <mesh
                                  name="Volumenkörper1(16)_1"
                                  geometry={
                                    nodes['Volumenkörper1(16)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(16)_1'].material
                                  }
                                />
                              </group>
                            </group>
                            <group
                              name="002_2002"
                              position={[-3.05316, 1.313136, 2.241697]}
                              rotation={[0, 0.575219, Math.PI / 2]}
                            >
                              <mesh
                                name="Volumenkörper1(17)"
                                geometry={nodes['Volumenkörper1(17)'].geometry}
                                material={nodes['Volumenkörper1(17)'].material}
                                position={[0, 0, 0]}
                              />
                            </group>
                            <group
                              name="002_2002_2"
                              position={[-3.05622, 1.313136, 3.220938]}
                              rotation={[0, -0.282495, Math.PI / 2]}
                            >
                              <group name="002_2002_1">
                                <mesh
                                  name="Volumenkörper1(17)_1"
                                  geometry={
                                    nodes['Volumenkörper1(17)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(17)_1'].material
                                  }
                                />
                              </group>
                            </group>
                            <group
                              name="002_2002_3"
                              position={[-3.5191, -0.953023, 2.238582]}
                              rotation={[0, -0.434075, -Math.PI / 2]}
                            >
                              <group name="002_2002_5">
                                <mesh
                                  name="Volumenkörper1(17)_2"
                                  geometry={
                                    nodes['Volumenkörper1(17)_2'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(17)_2'].material
                                  }
                                />
                              </group>
                            </group>
                            <group
                              name="002_2002_4"
                              position={[-3.517505, -0.953023, 3.234057]}
                              rotation={[0, -0.243606, -Math.PI / 2]}
                            >
                              <group name="002_2002_6">
                                <mesh
                                  name="Volumenkörper1(17)_3"
                                  geometry={
                                    nodes['Volumenkörper1(17)_3'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(17)_3'].material
                                  }
                                />
                              </group>
                            </group>
                            <group
                              name="002_2011"
                              position={[-6.30199, 1.705057, 0.767971]}
                              rotation={[-Math.PI, 0, 0.023249]}
                            >
                              <mesh
                                name="Volumenkörper1(18)"
                                geometry={nodes['Volumenkörper1(18)'].geometry}
                                material={nodes['Volumenkörper1(18)'].material}
                                position={[0, 0, 0]}
                              />
                              <mesh
                                name="Volumenkörper1(19)"
                                geometry={nodes['Volumenkörper1(19)'].geometry}
                                material={nodes['Volumenkörper1(19)'].material}
                                position={[0, 0, 0]}
                              />
                            </group>
                            <group
                              name="002_2011_2"
                              position={[-6.30199, -1.794943, 0.767971]}
                              rotation={[-Math.PI, 0, -2.266419]}
                            >
                              <group name="002_2011_1">
                                <mesh
                                  name="Volumenkörper1(18)_1"
                                  geometry={
                                    nodes['Volumenkörper1(18)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(18)_1'].material
                                  }
                                />
                                <mesh
                                  name="Volumenkörper1(19)_1"
                                  geometry={
                                    nodes['Volumenkörper1(19)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(19)_1'].material
                                  }
                                />
                              </group>
                            </group>
                            <group
                              name="DIN_7991_-_M3x12"
                              position={[-0.43399, 3.125057, 2.967971]}
                              rotation={[-Math.PI / 2, 0, Math.PI / 2]}
                            >
                              <mesh
                                name="Solid1(2)"
                                geometry={nodes['Solid1(2)'].geometry}
                                material={nodes['Solid1(2)'].material}
                                position={[0, 0, 0]}
                              />
                            </group>
                            <group
                              name="DIN_7991_-_M3x12_2"
                              position={[-5.93399, 3.125057, 2.967971]}
                              rotation={[-Math.PI / 2, 0, 1.570794]}
                            >
                              <group name="DIN_7991_-_M3x12_1">
                                <mesh
                                  name="Solid1(2)_1"
                                  geometry={nodes['Solid1(2)_1'].geometry}
                                  material={nodes['Solid1(2)_1'].material}
                                />
                              </group>
                            </group>
                            <group
                              name="ISO_4762_-_M4_x_16DIN_EN_ISO"
                              position={[0.49801, -2.794943, 3.967971]}
                              rotation={[Math.PI / 2, 0.724601, -Math.PI / 2]}
                            >
                              <mesh
                                name="Solid1(3)"
                                geometry={nodes['Solid1(3)'].geometry}
                                material={nodes['Solid1(3)'].material}
                                position={[0, 0, 0]}
                              />
                            </group>
                            <group
                              name="ISO_4762_-_M4_x_16DIN_EN_ISO_2"
                              position={[-6.80199, 2.505057, 3.967971]}
                              rotation={[-Math.PI / 2, 0.133317, Math.PI / 2]}
                            >
                              <mesh
                                name="Solid1(3)_1"
                                geometry={nodes['Solid1(3)_1'].geometry}
                                material={nodes['Solid1(3)_1'].material}
                              />
                            </group>
                            <group
                              name="ISO_4762_-_M4_x_16DIN_EN_ISO_3"
                              position={[0.29801, 2.505057, 3.967971]}
                              rotation={[Math.PI / 2, 1.44564, -Math.PI / 2]}
                            >
                              <mesh
                                name="Solid1(3)_2"
                                geometry={nodes['Solid1(3)_2'].geometry}
                                material={nodes['Solid1(3)_2'].material}
                              />
                            </group>
                            <group
                              name="ISO_4762_-_M4_x_16DIN_EN_ISO_4"
                              position={[-7.00199, -2.794943, 3.967971]}
                              rotation={[-Math.PI / 2, 0.635188, Math.PI / 2]}
                            >
                              <mesh
                                name="Solid1(3)_3"
                                geometry={nodes['Solid1(3)_3'].geometry}
                                material={nodes['Solid1(3)_3'].material}
                              />
                            </group>
                            <group
                              name="002_2020"
                              position={[-3.90499, 2.345057, 0.075058]}
                              rotation={[Math.PI / 2, 0, 0]}
                            >
                              <mesh
                                name="Volumenkörper1(22)"
                                geometry={nodes['Volumenkörper1(22)'].geometry}
                                material={nodes['Volumenkörper1(22)'].material}
                                position={[0, 0, 0]}
                              />
                            </group>
                            <group
                              name="002_2020_2"
                              position={[-2.59899, 2.345057, 0.075058]}
                              rotation={[Math.PI / 2, 0, 0]}
                            >
                              <group name="002_2020_1">
                                <mesh
                                  name="Volumenkörper1(22)_1"
                                  geometry={
                                    nodes['Volumenkörper1(22)_1'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(22)_1'].material
                                  }
                                />
                              </group>
                            </group>
                            <group
                              name="002_2020_3"
                              position={[-1.07699, -0.044943, -1.202029]}
                              rotation={[-0.182819, -1.570392, 0]}
                            >
                              <group name="002_2020_9">
                                <mesh
                                  name="Volumenkörper1(22)_2"
                                  geometry={
                                    nodes['Volumenkörper1(22)_2'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(22)_2'].material
                                  }
                                />
                              </group>
                            </group>
                            <group
                              name="002_2020_4"
                              position={[-1.07699, -0.044943, -2.702029]}
                              rotation={[0, -1.570535, 0]}
                            >
                              <group name="002_2020_10">
                                <mesh
                                  name="Volumenkörper1(22)_3"
                                  geometry={
                                    nodes['Volumenkörper1(22)_3'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(22)_3'].material
                                  }
                                />
                              </group>
                            </group>
                            <group
                              name="002_2020_5"
                              position={[-3.25199, 1.855057, -3.707029]}
                            >
                              <group name="002_2020_11">
                                <mesh
                                  name="Volumenkörper1(22)_4"
                                  geometry={
                                    nodes['Volumenkörper1(22)_4'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(22)_4'].material
                                  }
                                />
                              </group>
                            </group>
                            <group
                              name="002_2020_6"
                              position={[-3.25199, -1.944943, -3.707029]}
                              rotation={[0, 0, 0]}
                            >
                              <group name="002_2020_12">
                                <mesh
                                  name="Volumenkörper1(22)_5"
                                  geometry={
                                    nodes['Volumenkörper1(22)_5'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(22)_5'].material
                                  }
                                />
                              </group>
                            </group>
                            <group
                              name="002_2020_7"
                              position={[-3.90499, -2.434943, 0.075058]}
                              rotation={[-Math.PI / 2, 0, 0]}
                            >
                              <group name="002_2020_13">
                                <mesh
                                  name="Volumenkörper1(22)_6"
                                  geometry={
                                    nodes['Volumenkörper1(22)_6'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(22)_6'].material
                                  }
                                />
                              </group>
                            </group>
                            <group
                              name="002_2020_8"
                              position={[-2.59899, -2.434943, 0.075058]}
                              rotation={[-Math.PI / 2, 0, 0]}
                            >
                              <group name="002_2020_14">
                                <mesh
                                  name="Volumenkörper1(22)_7"
                                  geometry={
                                    nodes['Volumenkörper1(22)_7'].geometry
                                  }
                                  material={
                                    nodes['Volumenkörper1(22)_7'].material
                                  }
                                />
                              </group>
                            </group>
                            <group
                              name="002_2005"
                              position={[-3.90499, 2.685057, 0.075058]}
                              rotation={[Math.PI / 2, 0, 0]}
                            >
                              <mesh
                                name="Volumenkörper1(23)"
                                geometry={nodes['Volumenkörper1(23)'].geometry}
                                material={nodes['Volumenkörper1(23)'].material}
                                position={[0, 0, 0]}
                              />
                            </group>
                            <group
                              name="002_2005_2"
                              position={[-2.59899, -2.784943, 0.075058]}
                              rotation={[-Math.PI / 2, 0, 0]}
                            >
                              <mesh
                                name="Volumenkörper1(23)_1"
                                geometry={
                                  nodes['Volumenkörper1(23)_1'].geometry
                                }
                                material={
                                  nodes['Volumenkörper1(23)_1'].material
                                }
                              />
                            </group>
                            <group
                              name="002_2004"
                              position={[-2.59899, 2.345057, 0.075058]}
                              rotation={[-Math.PI / 2, 0, 0]}
                            >
                              <mesh
                                name="Solid1(4)"
                                geometry={nodes['Solid1(4)'].geometry}
                                material={nodes['Solid1(4)'].material}
                                position={[0, 0, 0]}
                              />
                            </group>
                            <group
                              name="002_2004_2"
                              position={[-3.90499, -2.434943, 0.075058]}
                              rotation={[Math.PI / 2, 0, 0]}
                            >
                              <mesh
                                name="Solid1(4)_1"
                                geometry={nodes['Solid1(4)_1'].geometry}
                                material={nodes['Solid1(4)_1'].material}
                              />
                            </group>
                            <group
                              name="DIN_913_-_M5_x_10"
                              position={[-3.25199, 1.855057, -2.542029]}
                              rotation={[Math.PI / 2, 1.387512, -Math.PI / 2]}
                            >
                              <mesh
                                name="Solid1(5)"
                                geometry={nodes['Solid1(5)'].geometry}
                                material={nodes['Solid1(5)'].material}
                                position={[0, 0, 0]}
                              />
                            </group>
                            <group
                              name="DIN_913_-_M5_x_10_2"
                              position={[-3.25199, -1.944943, -2.542029]}
                              rotation={[Math.PI / 2, -0.710278, -Math.PI / 2]}
                            >
                              <mesh
                                name="Solid1(5)_1"
                                geometry={nodes['Solid1(5)_1'].geometry}
                                material={nodes['Solid1(5)_1'].material}
                              />
                            </group>
                            <mesh
                              name="001_2001_Rev3"
                              geometry={nodes['001_2001_Rev3'].geometry}
                              material={nodes['001_2001_Rev3'].material}
                              position={[-3.665893, -0.284223, 0]}
                            />
                            <mesh
                              name="001_2002_Rev1"
                              geometry={nodes['001_2002_Rev1'].geometry}
                              material={nodes['001_2002_Rev1'].material}
                              position={[-3.25199, -0.094943, 0.767971]}
                            />
                            <mesh
                              name="001_2004"
                              geometry={nodes['001_2004'].geometry}
                              material={nodes['001_2004'].material}
                              position={[-3.25199, -0.044943, 3.767971]}
                              rotation={[0, 0, -Math.PI]}
                            />
                            <mesh
                              name="002_2003"
                              geometry={nodes['002_2003'].geometry}
                              material={nodes['002_2003'].material}
                              position={[-3.00199, 4.295057, 2.727971]}
                              rotation={[Math.PI / 2, 0, Math.PI / 2]}
                            />
                            <mesh
                              name="002_2012"
                              geometry={nodes['002_2012'].geometry}
                              material={nodes['002_2012'].material}
                              position={[-7.00199, -0.044943, 2.767971]}
                              rotation={[0, -1.570535, 0]}
                            />
                            <mesh
                              name="DIN_6325_Ø6x10"
                              geometry={nodes.DIN_6325_Ø6x10.geometry}
                              material={nodes.DIN_6325_Ø6x10.material}
                              position={[-3.25199, -2.544943, 4.107863]}
                              rotation={[0, 0, -0.133317]}
                            />
                          </group>
                          <group
                            name="004_016"
                            position={[1.296384, 9.001048, -6.525675]}
                            rotation={[0, Math.PI / 6, Math.PI / 2]}
                          >
                            <mesh
                              name="001_001"
                              geometry={nodes['001_001'].geometry}
                              material={nodes['001_001'].material}
                              position={[0, 0, 0]}
                              rotation={[0, 0, 0]}
                            />
                            <mesh
                              name="002_012"
                              geometry={nodes['002_012'].geometry}
                              material={nodes['002_012'].material}
                              position={[-1.866171, 0.012521, 0.021638]}
                              rotation={[Math.PI / 3, 0.592679, Math.PI / 2]}
                            />
                            <mesh
                              name="001_002_P_1_8"
                              geometry={nodes['001_002_P_1_8'].geometry}
                              material={nodes['001_002_P_1_8'].material}
                              position={[-1.016171, 0, 0]}
                              rotation={[-Math.PI / 6, 0, Math.PI / 2]}
                            />
                            <mesh
                              name="002_013"
                              geometry={nodes['002_013'].geometry}
                              material={nodes['002_013'].material}
                              position={[0.383829, 0, 0]}
                              rotation={[0, 0, -Math.PI / 2]}
                            />
                          </group>
                          <group
                            name="004_016_2"
                            position={[12.340871, 10.868707, -6.525675]}
                            rotation={[0, -Math.PI / 6, -Math.PI / 2]}
                          >
                            <group name="004_016_1">
                              <mesh
                                name="001_001_1"
                                geometry={nodes['001_001_1'].geometry}
                                material={nodes['001_001_1'].material}
                              />
                              <mesh
                                name="002_012_1"
                                geometry={nodes['002_012_1'].geometry}
                                material={nodes['002_012_1'].material}
                                position={[-1.866171, 0.012521, 0.021638]}
                                rotation={[Math.PI / 3, 0.592679, Math.PI / 2]}
                              />
                              <mesh
                                name="001_002_P_1_8_1"
                                geometry={nodes['001_002_P_1_8_1'].geometry}
                                material={nodes['001_002_P_1_8_1'].material}
                                position={[-1.016171, 0, 0]}
                                rotation={[-Math.PI / 6, 0, Math.PI / 2]}
                              />
                              <mesh
                                name="002_013_1"
                                geometry={nodes['002_013_1'].geometry}
                                material={nodes['002_013_1'].material}
                                position={[0.383829, 0, 0]}
                                rotation={[0, 0, -Math.PI / 2]}
                              />
                            </group>
                          </group>
                          <group
                            name="CAGTS10031212"
                            position={[12.956896, -20.115122, -5.592663]}
                            rotation={[0, -Math.PI / 6, Math.PI]}
                          >
                            <group
                              name="GTS0312"
                              position={[-0.7, -0.015, 1.905]}
                              rotation={[Math.PI / 2, 0, 1.431157]}
                            >
                              <group
                                name="PAP0405504"
                                position={[0, 0, 0]}
                                rotation={[0, 0, -1.169275]}
                              >
                                <mesh
                                  name="PAP0405504_1"
                                  geometry={nodes.PAP0405504_1.geometry}
                                  material={nodes.PAP0405504_1.material}
                                  position={[0, 0, 0]}
                                />
                              </group>
                              <group
                                name="PAP0405504_2"
                                position={[0, 0, -0.4]}
                                rotation={[0, 0, -0.458785]}
                              >
                                <mesh
                                  name="PAP0405504_3"
                                  geometry={nodes.PAP0405504_3.geometry}
                                  material={nodes.PAP0405504_3.material}
                                />
                              </group>
                              <mesh
                                name="R4SCH"
                                geometry={nodes.R4SCH.geometry}
                                material={nodes.R4SCH.material}
                                position={[4.033758, 0.57, 4.42049]}
                                rotation={[Math.PI / 2, 0, 0.107733]}
                              />
                              <mesh
                                name="VSTM4X16"
                                geometry={nodes.VSTM4X16.geometry}
                                material={nodes.VSTM4X16.material}
                                position={[-0.9, 1.239315, 0]}
                                rotation={[Math.PI / 2, 0, 0.873625]}
                              />
                              <mesh
                                name="D4"
                                geometry={nodes.D4.geometry}
                                material={nodes.D4.material}
                                position={[-0.9, 0.57, 0]}
                                rotation={[-Math.PI / 2, 0, -2.094395]}
                              />
                              <mesh
                                name="GTS100312"
                                geometry={nodes.GTS100312.geometry}
                                material={nodes.GTS100312.material}
                                position={[0, 0, 0]}
                                rotation={[0, 0, 0]}
                              />
                            </group>
                            <group
                              name="GTS0312_2"
                              position={[0.7, -0.015, 1.905]}
                              rotation={[-Math.PI / 2, 0, -1.710436]}
                            >
                              <group name="GTS0312_1">
                                <group
                                  name="PAP0405504_4"
                                  position={[0, 0, 0]}
                                  rotation={[0, 0, -1.169275]}
                                >
                                  <mesh
                                    name="PAP0405504_6"
                                    geometry={nodes.PAP0405504_6.geometry}
                                    material={nodes.PAP0405504_6.material}
                                  />
                                </group>
                                <group
                                  name="PAP0405504_2_1"
                                  position={[0, 0, -0.4]}
                                  rotation={[0, 0, -0.458785]}
                                >
                                  <mesh
                                    name="PAP0405504_7"
                                    geometry={nodes.PAP0405504_7.geometry}
                                    material={nodes.PAP0405504_7.material}
                                  />
                                </group>
                                <mesh
                                  name="R4SCH_1"
                                  geometry={nodes.R4SCH_1.geometry}
                                  material={nodes.R4SCH_1.material}
                                  position={[4.033758, 0.57, 4.42049]}
                                  rotation={[Math.PI / 2, 0, 0.107733]}
                                />
                                <mesh
                                  name="VSTM4X16_1"
                                  geometry={nodes.VSTM4X16_1.geometry}
                                  material={nodes.VSTM4X16_1.material}
                                  position={[-0.9, 1.239315, 0]}
                                  rotation={[Math.PI / 2, 0, 0.873625]}
                                />
                                <mesh
                                  name="D4_1"
                                  geometry={nodes.D4_1.geometry}
                                  material={nodes.D4_1.material}
                                  position={[-0.9, 0.57, 0]}
                                  rotation={[-Math.PI / 2, 0, -2.094395]}
                                />
                                <mesh
                                  name="GTS100312_1"
                                  geometry={nodes.GTS100312_1.geometry}
                                  material={nodes.GTS100312_1.material}
                                />
                              </group>
                            </group>
                            <mesh
                              name="GTS10030118_(derivato)"
                              geometry={
                                nodes['GTS10030118_(derivato)'].geometry
                              }
                              material={
                                nodes['GTS10030118_(derivato)'].material
                              }
                              position={[0, 0, 0]}
                            />
                          </group>
                          <group
                            name="CAGTS10031212_2"
                            position={[0.680359, -17.115122, -5.592663]}
                            rotation={[0, Math.PI / 6, 0]}
                          >
                            <group name="CAGTS10031212_1">
                              <group
                                name="GTS0312_3"
                                position={[-0.7, -0.015, 1.905]}
                                rotation={[Math.PI / 2, 0, 1.431157]}
                              >
                                <group
                                  name="PAP0405504_5"
                                  position={[0, 0, 0]}
                                  rotation={[0, 0, -1.169275]}
                                >
                                  <mesh
                                    name="PAP0405504_8"
                                    geometry={nodes.PAP0405504_8.geometry}
                                    material={nodes.PAP0405504_8.material}
                                  />
                                </group>
                                <group
                                  name="PAP0405504_2_2"
                                  position={[0, 0, -0.4]}
                                  rotation={[0, 0, -0.458785]}
                                >
                                  <mesh
                                    name="PAP0405504_9"
                                    geometry={nodes.PAP0405504_9.geometry}
                                    material={nodes.PAP0405504_9.material}
                                  />
                                </group>
                                <mesh
                                  name="R4SCH_2"
                                  geometry={nodes.R4SCH_2.geometry}
                                  material={nodes.R4SCH_2.material}
                                  position={[4.033758, 0.57, 4.42049]}
                                  rotation={[Math.PI / 2, 0, 0.107733]}
                                />
                                <mesh
                                  name="VSTM4X16_2"
                                  geometry={nodes.VSTM4X16_2.geometry}
                                  material={nodes.VSTM4X16_2.material}
                                  position={[-0.9, 1.239315, 0]}
                                  rotation={[Math.PI / 2, 0, 0.873625]}
                                />
                                <mesh
                                  name="D4_2"
                                  geometry={nodes.D4_2.geometry}
                                  material={nodes.D4_2.material}
                                  position={[-0.9, 0.57, 0]}
                                  rotation={[-Math.PI / 2, 0, -2.094395]}
                                />
                                <mesh
                                  name="GTS100312_2"
                                  geometry={nodes.GTS100312_2.geometry}
                                  material={nodes.GTS100312_2.material}
                                />
                              </group>
                              <group
                                name="GTS0312_2_1"
                                position={[0.7, -0.015, 1.905]}
                                rotation={[-Math.PI / 2, 0, -1.710436]}
                              >
                                <group name="GTS0312_4">
                                  <group
                                    name="PAP0405504_10"
                                    position={[0, 0, 0]}
                                    rotation={[0, 0, -1.169275]}
                                  >
                                    <mesh
                                      name="PAP0405504_11"
                                      geometry={nodes.PAP0405504_11.geometry}
                                      material={nodes.PAP0405504_11.material}
                                    />
                                  </group>
                                  <group
                                    name="PAP0405504_2_3"
                                    position={[0, 0, -0.4]}
                                    rotation={[0, 0, -0.458785]}
                                  >
                                    <mesh
                                      name="PAP0405504_12"
                                      geometry={nodes.PAP0405504_12.geometry}
                                      material={nodes.PAP0405504_12.material}
                                    />
                                  </group>
                                  <mesh
                                    name="R4SCH_3"
                                    geometry={nodes.R4SCH_3.geometry}
                                    material={nodes.R4SCH_3.material}
                                    position={[4.033758, 0.57, 4.42049]}
                                    rotation={[Math.PI / 2, 0, 0.107733]}
                                  />
                                  <mesh
                                    name="VSTM4X16_3"
                                    geometry={nodes.VSTM4X16_3.geometry}
                                    material={nodes.VSTM4X16_3.material}
                                    position={[-0.9, 1.239315, 0]}
                                    rotation={[Math.PI / 2, 0, 0.873625]}
                                  />
                                  <mesh
                                    name="D4_3"
                                    geometry={nodes.D4_3.geometry}
                                    material={nodes.D4_3.material}
                                    position={[-0.9, 0.57, 0]}
                                    rotation={[-Math.PI / 2, 0, -2.094395]}
                                  />
                                  <mesh
                                    name="GTS100312_3"
                                    geometry={nodes.GTS100312_3.geometry}
                                    material={nodes.GTS100312_3.material}
                                  />
                                </group>
                              </group>
                              <mesh
                                name="GTS10030118_(derivato)_1"
                                geometry={
                                  nodes['GTS10030118_(derivato)_1'].geometry
                                }
                                material={
                                  nodes['GTS10030118_(derivato)_1'].material
                                }
                              />
                            </group>
                          </group>
                          <group
                            name="001_106"
                            position={[8.731895, -20.115122, 1.725252]}
                            rotation={[Math.PI, Math.PI / 6, -Math.PI]}
                          >
                            <mesh
                              name="Volumenkörper1(29)"
                              geometry={nodes['Volumenkörper1(29)'].geometry}
                              material={nodes['Volumenkörper1(29)'].material}
                              position={[0, 0, 0]}
                            />
                          </group>
                          <group
                            name="001_106_2"
                            position={[4.055359, -17.115122, 0.253009]}
                            rotation={[0, Math.PI / 6, 0]}
                          >
                            <group name="001_106_1">
                              <mesh
                                name="Volumenkörper1(29)_1"
                                geometry={
                                  nodes['Volumenkörper1(29)_1'].geometry
                                }
                                material={
                                  nodes['Volumenkörper1(29)_1'].material
                                }
                              />
                            </group>
                          </group>
                          <group
                            name="Reduzierung_30-14"
                            position={[9.731895, -20.115122, -0.006799]}
                            rotation={[0, -Math.PI / 6, 0]}
                          >
                            <mesh
                              name="Volumenkörper1(30)"
                              geometry={nodes['Volumenkörper1(30)'].geometry}
                              material={nodes['Volumenkörper1(30)'].material}
                              position={[0, 0, 0]}
                            />
                          </group>
                          <group
                            name="Reduzierung_30-14_2"
                            position={[3.905359, -17.115122, -0.006799]}
                            rotation={[0, Math.PI / 6, -0.427969]}
                          >
                            <group name="Reduzierung_30-14_1">
                              <mesh
                                name="Volumenkörper1(30)_1"
                                geometry={
                                  nodes['Volumenkörper1(30)_1'].geometry
                                }
                                material={
                                  nodes['Volumenkörper1(30)_1'].material
                                }
                              />
                            </group>
                          </group>
                          <group
                            name="002_134"
                            position={[3.155359, -14.885122, -1.305837]}
                          >
                            <mesh
                              name="Volumenkörper1(31)"
                              geometry={nodes['Volumenkörper1(31)'].geometry}
                              material={nodes['Volumenkörper1(31)'].material}
                              position={[0, 0, 0]}
                            />
                            <mesh
                              name="Volumenkörper1(32)"
                              geometry={nodes['Volumenkörper1(32)'].geometry}
                              material={nodes['Volumenkörper1(32)'].material}
                              position={[0, 0, 0]}
                            />
                          </group>
                          <group
                            name="002_134_2"
                            position={[10.481895, -17.885122, -1.305837]}
                          >
                            <group name="002_134_1">
                              <mesh
                                name="Volumenkörper1(31)_1"
                                geometry={
                                  nodes['Volumenkörper1(31)_1'].geometry
                                }
                                material={
                                  nodes['Volumenkörper1(31)_1'].material
                                }
                              />
                              <mesh
                                name="Volumenkörper1(32)_1"
                                geometry={
                                  nodes['Volumenkörper1(32)_1'].geometry
                                }
                                material={
                                  nodes['Volumenkörper1(32)_1'].material
                                }
                              />
                            </group>
                          </group>
                          <group
                            name="001_102"
                            position={[6.818627, 11.184877, -0.36087]}
                            rotation={[-Math.PI, Math.PI / 6, Math.PI / 2]}
                          >
                            <mesh
                              name="Volumenkörper1(33)"
                              geometry={nodes['Volumenkörper1(33)'].geometry}
                              material={nodes['Volumenkörper1(33)'].material}
                              position={[0, 0, 0]}
                            />
                          </group>
                          <group
                            name="001_102_2"
                            position={[6.818627, 8.684877, -0.36087]}
                            rotation={[0, Math.PI / 6, -Math.PI / 2]}
                          >
                            <mesh
                              name="Volumenkörper1(33)_1"
                              geometry={nodes['Volumenkörper1(33)_1'].geometry}
                              material={nodes['Volumenkörper1(33)_1'].material}
                            />
                          </group>
                          <group
                            name="DIN_912_-_M6_x_1_x_40_x_24"
                            position={[8.586627, -2.197123, 2.63913]}
                            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                          >
                            <mesh
                              name="Solid1(6)"
                              geometry={nodes['Solid1(6)'].geometry}
                              material={nodes['Solid1(6)'].material}
                              position={[0, 0, 0]}
                            />
                          </group>
                          <group
                            name="DIN_912_-_M6_x_1_x_40_x_24_2"
                            position={[8.586627, -5.733122, 2.63913]}
                            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                          >
                            <group name="DIN_912_-_M6_x_1_x_40_x_24_1">
                              <mesh
                                name="Solid1(6)_1"
                                geometry={nodes['Solid1(6)_1'].geometry}
                                material={nodes['Solid1(6)_1'].material}
                              />
                            </group>
                          </group>
                          <group
                            name="DIN_912_-_M6_x_1_x_40_x_24_3"
                            position={[5.050627, -5.733122, 2.63913]}
                            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                          >
                            <group name="DIN_912_-_M6_x_1_x_40_x_24_5">
                              <mesh
                                name="Solid1(6)_2"
                                geometry={nodes['Solid1(6)_2'].geometry}
                                material={nodes['Solid1(6)_2'].material}
                              />
                            </group>
                          </group>
                          <group
                            name="DIN_912_-_M6_x_1_x_40_x_24_4"
                            position={[5.050627, -2.197123, 2.63913]}
                            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                          >
                            <group name="DIN_912_-_M6_x_1_x_40_x_24_6">
                              <mesh
                                name="Solid1(6)_3"
                                geometry={nodes['Solid1(6)_3'].geometry}
                                material={nodes['Solid1(6)_3'].material}
                              />
                            </group>
                          </group>
                          <group
                            name="002_140"
                            position={[6.165627, -9.993123, 1.246217]}
                            rotation={[Math.PI, 0, 0]}
                          >
                            <mesh
                              name="Volumenkörper1(35)"
                              geometry={nodes['Volumenkörper1(35)'].geometry}
                              material={nodes['Volumenkörper1(35)'].material}
                              position={[0, 0, 0]}
                            />
                          </group>
                          <group
                            name="002_140_2"
                            position={[7.471627, 1.864877, 1.246217]}
                            rotation={[-Math.PI, 0, -Math.PI]}
                          >
                            <group name="002_140_1">
                              <mesh
                                name="Volumenkörper1(35)_1"
                                geometry={
                                  nodes['Volumenkörper1(35)_1'].geometry
                                }
                                material={
                                  nodes['Volumenkörper1(35)_1'].material
                                }
                              />
                            </group>
                          </group>
                          <group
                            name="002_099"
                            position={[6.44087, 11.184877, 3.693424]}
                            rotation={[1.289761, 0.447832, 0.588003]}
                          >
                            <mesh
                              name="Volumenkörper1(44)"
                              geometry={nodes['Volumenkörper1(44)'].geometry}
                              material={nodes['Volumenkörper1(44)'].material}
                              position={[0, 0, 0]}
                            />
                          </group>
                          <group
                            name="002_099_2"
                            position={[7.196384, 8.684877, 3.693424]}
                            rotation={[1.289761, -0.447832, -0.588003]}
                          >
                            <group name="002_099_1">
                              <mesh
                                name="Volumenkörper1(44)_1"
                                geometry={
                                  nodes['Volumenkörper1(44)_1'].geometry
                                }
                                material={
                                  nodes['Volumenkörper1(44)_1'].material
                                }
                              />
                            </group>
                          </group>
                          <group
                            name="002_011"
                            position={[1.296384, 7.984878, -7.725676]}
                          >
                            <mesh
                              name="Volumenkörper1(45)"
                              geometry={nodes['Volumenkörper1(45)'].geometry}
                              material={nodes['Volumenkörper1(45)'].material}
                              position={[0, 0, 0]}
                            />
                          </group>
                          <group
                            name="002_011_2"
                            position={[12.340871, 11.884877, -7.725676]}
                          >
                            <mesh
                              name="Volumenkörper1(45)_1"
                              geometry={nodes['Volumenkörper1(45)_1'].geometry}
                              material={nodes['Volumenkörper1(45)_1'].material}
                            />
                          </group>
                          <mesh
                            name="001_2011"
                            geometry={nodes['001_2011'].geometry}
                            material={nodes['001_2011'].material}
                            position={[6.818627, -21.465122, -0.36087]}
                            rotation={[-Math.PI / 2, 0, 0]}
                          />
                          <mesh
                            name="6x25"
                            geometry={nodes['6x25'].geometry}
                            material={nodes['6x25'].material}
                            position={[7.471627, 1.374877, 1.246217]}
                            rotation={[Math.PI / 2, 0, -3.114168]}
                          />
                          <mesh
                            name="Schlauch_6x25"
                            geometry={nodes.Schlauch_6x25.geometry}
                            material={nodes.Schlauch_6x25.material}
                            position={[6.165627, -9.135122, 1.246217]}
                            rotation={[-Math.PI / 2, 0, 0]}
                          />
                          <mesh
                            name="Schlauch_6_2"
                            geometry={nodes.Schlauch_6_2.geometry}
                            material={nodes.Schlauch_6_2.material}
                            position={[3.155359, -14.475122, -1.305837]}
                            rotation={[Math.PI / 2, 0, -1.274847]}
                          />
                          <mesh
                            name="Schlauch_6_1"
                            geometry={nodes.Schlauch_6_1.geometry}
                            material={nodes.Schlauch_6_1.material}
                            position={[12.340895, 12.734878, -3.84106]}
                            rotation={[-Math.PI, 0, -0.893581]}
                          />
                          <mesh
                            name="Schlauch_6_3"
                            geometry={nodes.Schlauch_6_3.geometry}
                            material={nodes.Schlauch_6_3.material}
                            position={[10.481895, -17.39394, -1.305837]}
                            rotation={[Math.PI / 2, 0, 0]}
                          />
                          <mesh
                            name="Schlauch_6_4"
                            geometry={nodes.Schlauch_6_4.geometry}
                            material={nodes.Schlauch_6_4.material}
                            position={[5.551627, 1.864877, 1.246217]}
                            rotation={[0.181067, Math.PI / 2, 0]}
                          />
                        </group>
                        <group
                          name="_098"
                          position={[0.000015, 0, 0.000034]}
                          rotation={[0, 0, 0]}
                        >
                          <mesh
                            name="_099"
                            geometry={nodes._099.geometry}
                            material={nodes._099.material}
                            position={[0.000015, 0, 0.000034]}
                            rotation={[0, 0, 0]}
                          />
                        </group>
                        <group
                          name="_100"
                          position={[0.000015, 0, 0.000034]}
                          rotation={[0, 0, 0]}
                        >
                          <mesh
                            name="_101"
                            geometry={nodes._101.geometry}
                            material={nodes._101.material}
                            position={[0.000015, 0, 0.000034]}
                            rotation={[0, 0, 0]}
                          />
                        </group>
                        <group
                          name="_102"
                          position={[0.000015, 0, 0.000034]}
                          rotation={[0, 0, 0]}
                        >
                          <mesh
                            name="_103"
                            geometry={nodes._103.geometry}
                            material={nodes._103.material}
                            position={[0.000015, 0, 0.000034]}
                            rotation={[0, 0, 0]}
                          />
                        </group>
                        <group
                          name="_104"
                          position={[0.000015, 0, 0.000034]}
                          rotation={[0, 0, 0]}
                        >
                          <mesh
                            name="_105"
                            geometry={nodes._105.geometry}
                            material={nodes._105.material}
                            position={[0.000015, 0, 0.000034]}
                            rotation={[0, 0, 0]}
                          />
                        </group>
                        <group
                          name="_106"
                          position={[0.000015, 0, 0.000034]}
                          rotation={[0, 0, 0]}
                        >
                          <mesh
                            name="_107"
                            geometry={nodes._107.geometry}
                            material={nodes._107.material}
                            position={[0.000015, 0, 0.000034]}
                            rotation={[0, 0, 0]}
                          />
                          <mesh
                            name="_108"
                            geometry={nodes._108.geometry}
                            material={nodes._108.material}
                            position={[0.000015, 0, 0.000034]}
                            rotation={[0, 0, 0]}
                          />
                          <mesh
                            name="_109"
                            geometry={nodes._109.geometry}
                            material={nodes._109.material}
                            position={[0.000015, 0, 0.000034]}
                            rotation={[0, 0, 0]}
                          />
                          <mesh
                            name="_110"
                            geometry={nodes._110.geometry}
                            material={nodes._110.material}
                            position={[0.000015, 0, 0.000034]}
                            rotation={[0, 0, 0]}
                          />
                          <mesh
                            name="_111"
                            geometry={nodes._111.geometry}
                            material={materials['material21.001']}
                            position={[0.000015, 0, 0.000034]}
                            rotation={[0, 0, 0]}
                          />
                          <mesh
                            name="_112"
                            geometry={nodes._112.geometry}
                            material={materials['material22.001']}
                            position={[0.000015, 0, 0.000034]}
                            rotation={[0, 0, 0]}
                          />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group
                  name="_066"
                  position={[0, 0.102548, 0.2126]}
                  rotation={[0, 1.570535, 0]}
                  scale={0.001}
                >
                  <group
                    name="_067"
                    position={[-0.000015, -0.000002, -0.000002]}
                    rotation={[0, 0, 0]}
                  >
                    <mesh
                      name="_068"
                      geometry={nodes._068.geometry}
                      material={nodes._068.material}
                      position={[-0.000015, -0.000002, -0.000002]}
                      rotation={[0, 0, 0]}
                    />
                  </group>
                  <group
                    name="_069"
                    position={[-0.000015, -0.000002, -0.000002]}
                    rotation={[0, 0, 0]}
                  >
                    <mesh
                      name="_070"
                      geometry={nodes._070.geometry}
                      material={nodes._070.material}
                      position={[-0.000015, -0.000002, -0.000002]}
                      rotation={[0, 0, 0]}
                    />
                    <mesh
                      name="_071"
                      geometry={nodes._071.geometry}
                      material={nodes._071.material}
                      position={[-0.000015, -0.000002, -0.000002]}
                      rotation={[0, 0, 0]}
                    />
                    <mesh
                      name="_072"
                      geometry={nodes._072.geometry}
                      material={nodes._072.material}
                      position={[-0.000015, -0.000002, -0.000002]}
                      rotation={[0, 0, 0]}
                    />
                    <mesh
                      name="_073"
                      geometry={nodes._073.geometry}
                      material={nodes._073.material}
                      position={[-0.000015, -0.000002, -0.000002]}
                      rotation={[0, 0, 0]}
                    />
                    <mesh
                      name="_074"
                      geometry={nodes._074.geometry}
                      material={nodes._074.material}
                      position={[-0.000015, -0.000002, -0.000002]}
                      rotation={[0, 0, 0]}
                    />
                    <mesh
                      name="_075"
                      geometry={nodes._075.geometry}
                      material={nodes._075.material}
                      position={[-0.000015, -0.000002, -0.000002]}
                      rotation={[0, 0, 0]}
                    />
                    <mesh
                      name="_076"
                      geometry={nodes._076.geometry}
                      material={nodes._076.material}
                      position={[-0.000015, -0.000002, -0.000002]}
                      rotation={[0, 0, 0]}
                    />
                  </group>
                </group>
              </group>
              <group
                name="_052"
                position={[0.00015, 0.11985, 0]}
                rotation={[0, 0.000001, 0]}
                scale={0.001}
              >
                <group
                  name="_057"
                  position={[0.000007, 0, 0]}
                  rotation={[0, 0, 0]}
                >
                  <mesh
                    name="_060"
                    geometry={nodes._060.geometry}
                    material={nodes._060.material}
                    position={[0.000007, 0, 0]}
                    rotation={[0, 0, 0]}
                  />
                  <mesh
                    name="_061"
                    geometry={nodes._061.geometry}
                    material={nodes._061.material}
                    position={[0.000007, 0, 0]}
                    rotation={[0, 0, 0]}
                  />
                  <mesh
                    name="_062"
                    geometry={nodes._062.geometry}
                    material={nodes._062.material}
                    position={[0.000007, 0, 0]}
                    rotation={[0, 0, 0]}
                  />
                  <mesh
                    name="_063"
                    geometry={nodes._063.geometry}
                    material={nodes._063.material}
                    position={[0.000007, 0, 0]}
                    rotation={[0, 0, 0]}
                  />
                  <mesh
                    name="_064"
                    geometry={nodes._064.geometry}
                    material={nodes._064.material}
                    position={[0.000007, 0, 0]}
                    rotation={[0, 0, 0]}
                  />
                  <mesh
                    name="_065"
                    geometry={nodes._065.geometry}
                    material={nodes._065.material}
                    position={[0.000007, 0, 0]}
                    rotation={[0, 0, 0]}
                  />
                </group>
              </group>
            </group>
            <group
              name="_042"
              position={[0, 0.15185, 0]}
              rotation={[0, -1.570535, 0]}
              scale={0.001}
            >
              <group name="_045" position={[0, 0, 0]} rotation={[0, 0, 0]}>
                <mesh
                  name="_046"
                  geometry={nodes._046.geometry}
                  material={nodes._046.material}
                  position={[0, 0, 0]}
                  rotation={[0, 0, 0]}
                />
                <mesh
                  name="_047"
                  geometry={nodes._047.geometry}
                  material={nodes._047.material}
                  position={[0, 0, 0]}
                  rotation={[0, 0, 0]}
                />
                <mesh
                  name="_048"
                  geometry={nodes._048.geometry}
                  material={nodes._048.material}
                  position={[0, 0, 0]}
                  rotation={[0, 0, 0]}
                />
                <mesh
                  name="_049"
                  geometry={nodes._049.geometry}
                  material={nodes._049.material}
                  position={[0, 0, 0]}
                  rotation={[0, 0, 0]}
                />
                <mesh
                  name="_050"
                  geometry={nodes._050.geometry}
                  material={nodes._050.material}
                  position={[0, 0, 0]}
                  rotation={[0, 0, 0]}
                />
                <mesh
                  name="_051"
                  geometry={nodes._051.geometry}
                  material={nodes._051.material}
                  position={[0, 0, 0]}
                  rotation={[0, 0, 0]}
                />
              </group>
            </group>
          </group>
          <mesh
            name="_036"
            geometry={nodes._036.geometry}
            material={nodes._036.material}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.001}
          />
          <mesh
            name="_037"
            geometry={nodes._037.geometry}
            material={materials['material11.001']}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.001}
          />
          <mesh
            name="_038"
            geometry={nodes._038.geometry}
            material={nodes._038.material}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.001}
          />
          <mesh
            name="_039"
            geometry={nodes._039.geometry}
            material={nodes._039.material}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.001}
          />
          <mesh
            name="_040"
            geometry={nodes._040.geometry}
            material={materials['material02.001']}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.001}
          />
        </group>
        <PerspectiveCamera
          makeDefault
          name="Main_Camera"
          far={10000000000}
          near={100}
          fov={22.895192}
          position={[-235.441559, 250, 179.743973]}
          rotation={[0.055797, -1.128209, 0.05043]}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/robotAnimated.glb')
