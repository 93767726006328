import React from 'react'

export const SwipeDownIcon = ({ height = 30, color = 'black', style }) => (
  <svg x="0px" y="0px" viewBox="0 0 58.1 90" height={height} {...style}>
    <g fill={color}>
      <polygon points="56.4,17 54.2,19.2 54.2,0 51.7,0 51.7,19.2 49.5,17 47.7,18.7 52.9,23.9 58.1,18.7 	" />
      <path
        d="M46.9,43.9c-1.3-1.1-3.1-1.4-5.4-1.1c-0.2-3.1-1.3-6.1-5.5-6.5c-1.4-0.1-2.5,0-3.3,0.2l-1-24.2c-0.2-4.9-2.7-7.5-7-7.3
		c-4.1,0.2-6.3,2.7-6.3,7.1c-1.2-1.2-2.9-1.8-5.1-1.7c-4.3,0.3-6.5,3.1-6.2,8l1.2,26.4c-0.5,0-1-0.1-1.4-0.1c-2.5,0-5.5,1.3-6.1,6.9
		c0,0.2-0.5,4-0.6,9.1c-0.1,7,1.2,12.9,3.8,16.9c4.7,7.3,11,10,11.7,10.2c0.5,0.3,4.4,2,11.1,2c7.1,0,11.7-2.4,12.3-2.7
		C39.7,86.8,50,81.5,50,63.8c0-5.7-0.1-8.3-0.4-13.2C49.4,47.3,48.6,45.2,46.9,43.9z M37.7,85.1L37.7,85.1
		c-0.1,0.1-4.5,2.5-11.2,2.5c-6.4,0-10-1.7-10-1.7l-0.1-0.1c-0.1,0-6.1-2.4-10.5-9.3c-2.3-3.6-3.5-9.1-3.4-15.6
		c0.1-5,0.6-8.8,0.6-8.9c0.5-4.2,2.2-4.7,3.7-4.8c0.5,0,1,0,1.5,0.1L9,64l2.5-0.1l-2-45.6c-0.3-4.5,1.9-5.3,3.9-5.4
		c2-0.1,4.3,0.3,4.5,4.9L20,52.7h0l0,0.9l2.5-0.1l-1.7-40.7c-0.2-4.5,2-5.2,4-5.3c2-0.1,4.3,0.4,4.5,4.9l1.6,37l2.5-0.1L32.8,39
		c0.6-0.2,1.5-0.5,3-0.3c2.1,0.2,3.3,1.2,3.3,5.8v10h2.5v-9.3c1.7-0.3,3-0.1,3.8,0.6c1,0.8,1.6,2.4,1.7,5c0.3,4.4,0.4,7,0.4,13
		C47.5,80.2,38.1,84.9,37.7,85.1z"
      />
    </g>
  </svg>
)
