import styled from '@emotion/styled'

import { breakpoints, fontSizes, space, colors } from '../../utils/styles'

export const Scroll = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  overflow-y: auto;
`

export const Dot = styled.div`
  pointer-events: none;
  position: sticky;
  top: 0px;
  right: 0px;
  float: right;
  text-align: right;
  display: inline-block;
  max-width: 600px;
  padding: ${space[20]};
  color: white;
  text-shadow: 2px 2px 5px #000;
  line-height: 1.6em;
  font-size: ${fontSizes.lg};
  letter-spacing: 1.5px;
  scroll-snap-align: start;

  @media (max-width: ${breakpoints.s}px) {
    padding: ${space[10]} ${space[5]};
  }
`

export const H1 = styled.h1`
  -webkit-font-smoothing: auto;
  pointer-events: none;
  color: white;
  font-size: ${fontSizes['7xl']};
  font-weight: 100;
  line-height: 1em;
  margin: 0;
  margin-bottom: 0.25em;

  @media (max-width: ${breakpoints.l}px) {
    font-size: ${fontSizes['4xl']};
  }
`
