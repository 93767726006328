import React from 'react'

import { useWindowDimensions } from '../../utils/hooks'
import { Container, breakpoints } from '../../utils/styles'
import { Wrapper, Flex } from './styles'
import Logo from './Logo'
import { Link } from 'gatsby'

const Navigation = ({ color = 'black', position = 'relative' }) => {
  const { width } = useWindowDimensions()

  return (
    <Wrapper color={color} position={position}>
      <Container>
        <Flex>
          <Link to="/">
            <Logo
              height={width > breakpoints.s ? '4rem' : '3rem'}
              logoVisible={true}
            />
          </Link>
        </Flex>
      </Container>
    </Wrapper>
  )
}

export default Navigation
