import React, { forwardRef } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { invalidate } from '@react-three/fiber'

import { Scroll, Dot, H1 } from './styles'

const Overlay = forwardRef(({ caption, scroll, children }, ref) => {
  const intl = useIntl()

  return (
    <Scroll
      ref={ref}
      onScroll={e => {
        scroll.current =
          e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)
        invalidate()
      }}
    >
      <div style={{ height: '400vh' }}>
        <Dot>
          <H1>Lightweight System</H1>
          {intl.locale === 'de' ? (
            <p>
              Das HERGA-Lightweight System ist ein End Of Arm Tool (EOAT) für
              eine Vielzahl von Greif und vakuumbasierte
              Pick-and-Place-Operationen. Durch die Möglichkeit von Einsatz von
              Vakuum und Pneumatischen Greifern, ist eine Vielzahl von
              Einsatzmöglichkeiten gegeben.
            </p>
          ) : (
            <p>
              The HERGA-Lightweight system is an End Of Arm Tool (EOAT) for a
              variety of gripping and vacuum-based pick-and-place operations.
              Due to the possibility of using vacuum and pneumatic grippers, a
              wide range of applications is given.
            </p>
          )}
        </Dot>
      </div>
      <div style={{ height: '300vh' }}>
        <Dot>
          {intl.locale === 'de' ? <H1>Sauger</H1> : <H1>Suction cup</H1>}
          {intl.locale === 'de' ? (
            <p>
              Dank der unzähligen Anpassungsmöglichkeiten lässt sich das
              HERGA-Lightweight-System perfekt an Ihre individuellen Bedürfnisse
              anpassen. So können nahezu alle Teile des Herga Leichtbausystems
              mit dem Basissatz des Light Weight Systems kombiniert werden.
              Sehen Sie hierzu auch unseren Katalog der einzelteile.
            </p>
          ) : (
            <p>
              Thanks to the countless customization options, the
              HERGA-Lightweight System can be perfectly adapted to your
              individual needs. Thus, almost all parts of the HERGA -Lightweight
              System can be combined with the basic set of the lightweight
              system. For further information have a look at our catalog of
              individual parts.
            </p>
          )}
        </Dot>
      </div>
      <div style={{ height: '100vh' }}>
        <Dot>
          {intl.locale === 'de' ? <H1>Greifer</H1> : <H1>Gripper</H1>}
          {intl.locale === 'de' ? (
            <p>
              Durch das modulare Baukastensystem und der 3 Dimensionalen
              Einstellungsmöglichkeit der Sauger ist das
              HERGA-Lightweight-System besonders für das Greifen von
              3D-Freiformflächen geeignet. Durch die mitgelieferte Software ist
              die Anpassung nicht nur vom Greifer an sich möglich, sondern auch
              die Handlungen sind individuell.
            </p>
          ) : (
            <p>
              Due to the modular construction system and the 3-dimensional
              adjustment possibility of the suction cups, the HERGA-Lightweight
              System is particularly suitable for gripping 3D-freeform surfaces.
              Due to the included software, the adaptation is not only possible
              of the gripper itself, but also the actions are individual.
            </p>
          )}
        </Dot>
      </div>
      {children}
    </Scroll>
  )
})

export default Overlay
