import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { breakpoints, fontSizes, space } from '../../utils/styles'

export const Wrapper = styled.div`
  color: ${({ color }) => (color ? color : 'black')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${space[5]} 0;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
  }
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;

  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 2rem;
    align-items: center;
  }
`

export const Item = styled(Link)`
  color: ${({ color }) => (color ? color : 'black')};
  line-height: 1.563;
  font-size: ${fontSizes.md};
  text-decoration: none;
  margin-right: ${space[10]};

  & > :last-child {
    margin-right: 0px;
  }
`

export const SocialMediaLinks = styled.ul`
  list-style: none outside;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    line-height: 1.563;
    margin-bottom: 0.25rem;
  }

  a {
    color: ${({ color }) => (color ? color : 'black')};
    text-decoration: none;
    margin-left: 5px;
  }
`

export const Cards = styled.div`
  text-align: right;

  svg {
    ${({ color }) => (color ? color : 'black')};
    margin-left: 0.5rem;
  }

  @media (max-width: ${breakpoints.s}px) {
    text-align: center;
  }
`

export const Flags = styled.div`
  & > * {
    margin-right: ${space[2.5]};
  }

  & > :last-child {
    margin-right: 0px;
  }
`
