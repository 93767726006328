import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { fontSizes, space } from '../../utils/styles'

export const LogoWrapepr = styled.span`
  cursor: pointer;
  opacity: ${({ logoVisible }) => (logoVisible ? '1' : '0')};
`

export const Wrapper = styled.div`
  position: ${({ position }) => position};
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  color: ${({ color }) => (color ? color : 'black')};
  padding: ${space[10]} 0;
`

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > * {
    margin-right: ${space[10]};
  }

  & > :last-child {
    margin-right: 0px;
  }
`

export const Item = styled(Link)`
  color: ${({ color }) => (color ? color : 'black')};
  line-height: 1.563;
  text-transform: uppercase;
  font-size: ${fontSizes['2xl']};
  text-decoration: none;
  margin-bottom: 0.25rem;
`
