import React from 'react'

import { LogoWrapepr } from './styles'

const Logo = ({ color, height = 32, onClick, logoVisible }) => (
  <LogoWrapepr onClick={onClick} logoVisible={logoVisible}>
    <svg height={height} viewBox="0 0 1000 1000">
      <rect x="0" fill="#1A1F52" width="1000" height="1000" />
      <polygon
        fill="#FFFFFF"
        points="116,124 332,124 332,388 464,388 464,592 332,592 332,856 116,856 	"
      />
      <polygon
        fill="#FFFFFF"
        points="541.8,124 541.8,856 889.8,856 661.8,500 889.8,124 	"
      />
    </svg>
  </LogoWrapepr>
)

export default Logo
